import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

ReactDOM.render((
    <BrowserRouter>
        <title>Emily & Alex Wedding</title>
        <App />
    </BrowserRouter>
), document.getElementById('root'))


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
