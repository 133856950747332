import React from 'react';
import '../App.css';
import {
    Layout, Row, Col, Typography, Card
} from 'antd';
import 'antd/dist/antd.css';
import {Navigation} from "../components/navigation";
import {Content} from "antd/es/layout/layout";
import giftList from "../assets/GiftList.png";
const {Meta} = Card

class GiftList extends React.Component<any, any> {
    state = {
        collapsed: false,
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    nextPath(path) {
        this.props.history.push(path);
    }

    handleClick(url) {
        window.open(url, '_blank');
    }

    render() {
        return (
            <Layout style={{minHeight: '100vh', backgroundColor: '#fff0f6'}}>
                <Navigation page={'gift'} history={this.props.history}/>
                <Layout className="site-layout" style={{backgroundColor: '#fff0f6'}}>
                    <Content style={{margin: '0 16px'}}>
                        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                            <Row><br/><br/><br/></Row>
                            <Col xs={24} xl={18}>
                                <Card
                                    hoverable
                                    size="default"
                                    onClick={this.handleClick.bind(this, "https://www.marriagegiftlist.com/DP5JW6")}
                                    cover={<img alt="our gift list"
                                                src={giftList}/>}>
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default GiftList;
