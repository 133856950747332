import React from 'react';
import '../App.css';
import {
    Layout,
    Typography,
    Row,
    Card,
    Col
} from 'antd';
import pumpkin from '../assets/pumpkin.png';
import 'antd/dist/antd.css';
import accommodation from "../assets/accommodation.jpeg";
import caerLlan from "../assets/caerLlan.jpeg";
import food from "../assets/BaconandEggCanapes-014.jpeg";
import gift from "../assets/Wedding-Gifts-500x350.jpeg"
import blog from "../assets/blogging-e1484908296381.jpeg"
import photos from "../assets/photographer.jpeg"
import taxi from "../assets/taxi.jpeg"
import { WeddingSteps } from "../components/steps"

const {Content, Footer} = Layout;
const {Title} = Typography;
const {Meta} = Card
let width = window.innerWidth;
let date = ""

if (width <= 768) {
   date = "29.10.22"
} else {
    date = "29th October 2022"
}

class Home extends React.Component<any, any> {
    nextPath(path) {
        this.props.history.push(path);
    }

    render() {
        return (
            <Layout style={{padding: '0 24px 24px', backgroundColor: '#fff0f6'}}>
                <Content
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    <Title level={1} style={{fontSize: 80, fontFamily: 'Brush Script MT', textAlign: 'center'}}>Emily
                        Stubbs & Alex Le Peltier </Title>
                    <Title level={1}
                           style={{fontSize: 60, fontFamily: 'Brush Script MT', textAlign: 'center'}}>{date}</Title>
                    <Title level={2} style={{fontFamily: 'Brush Script MT', fontSize: 40}}>Keep track of what we're
                        doing</Title>
                   <WeddingSteps step={5}/>
                    <Row>
                        <Title level={2} style={{fontFamily: 'Brush Script MT', fontSize: 40}}>Explore our
                            website</Title>
                    </Row>
                    <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                        <Col xs={24} xl={8}>
                            <Card
                                hoverable
                                onClick={() => this.nextPath('/accommodation')}
                                size="default"

                                cover={<img alt="accommodation" height={250}
                                            src={accommodation}/>}
                            >
                                <Meta title="Accommodation"/>
                            </Card>
                            <Row><br/></Row>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Card
                                hoverable
                                onClick={() => this.nextPath('/venue')}
                                size="default"
                                cover={<img alt="venue" height={250}
                                            src={caerLlan}/>}
                            >
                                <Meta title="Venue"/>
                            </Card>
                            <Row><br/></Row>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Card
                                hoverable
                                onClick={() => this.nextPath('/gift')}
                                size="default"
                                cover={<img alt="gift list" height={250}
                                            src={gift}/>}
                            >
                                <Meta title="Gift List"/>
                            </Card>
                            <Row><br/></Row>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Card
                                hoverable
                                onClick={() => this.nextPath('/blog')}
                                size="default"
                                cover={<img alt="blog" height={250}
                                            src={blog}/>}
                            >
                                <Meta title="Blog"/>
                            </Card>
                            <Row><br/></Row>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Card
                                hoverable
                                onClick={() => this.nextPath('/photos')}
                                size="default"
                                cover={<img alt="photos" height={250}
                                            src={photos}/>}
                            >
                                <Meta title="Photos"/>
                            </Card>
                            <Row><br/></Row>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Card
                                hoverable
                                onClick={() => this.nextPath('/travel')}
                                size="default"
                                cover={<img alt="travel" height={250}
                                            src={taxi}/>}
                            >
                                <Meta title="Travel"/>
                            </Card>
                            <Row><br/></Row>
                        </Col>
                    </Row>
                </Content>
                <Footer style={{textAlign: 'right', backgroundColor: '#fff0f6'}}>
                    <img
                        src={pumpkin}
                        className="pumpkin"
                        alt="pumpkin"
                    />
                </Footer>
            </Layout>
        );
    }
}

export default Home;
