import React from 'react';
import '../App.css';
import {
    Menu, Layout, Row, Empty, Col
} from 'antd';
import 'antd/dist/antd.css';
import {Navigation} from "../components/navigation";
import {Content} from "antd/es/layout/layout";

class Venue extends React.Component<any, any> {
    state = {
        collapsed: false,
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    nextPath(path) {
        this.props.history.push(path);
    }

    render() {
        return (
            <Layout style={{minHeight: '100vh', backgroundColor: '#fff0f6'}}>
                <Navigation page={'venue'} history={this.props.history}/>
                <Layout className="site-layout" style={{backgroundColor: '#fff0f6'}}>
                    <Content style={{margin: '0 16px'}}>
                        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                            <Row><br/><br/></Row>
                            <Col xs={24} xl={8}/>
                            <Col xs={24} xl={8}>
                                <Empty
                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                    imageStyle={{
                                        height: 60,
                                    }}
                                    description={
                                        <span>
       Oh no! We haven't got any information on our venue yet. Keep an eye on this page more information will be coming soon.
      </span>
                                    }
                                >
                                </Empty>,
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default Venue;
