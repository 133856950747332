import React from 'react';
import '../App.css';
import {
    Menu, Layout, Card, Row, Col, Typography
} from 'antd';
import 'antd/dist/antd.css';
import premmierInn from '../assets/premierInn.png'
import punchHouse from '../assets/punchHouse.jpg'
import creates from '../assets/creates.jpg'
import kingsHead from '../assets/kingsHeadMonmouth.jpg'
import {Navigation} from "../components/navigation";
import {Content} from "antd/es/layout/layout";

const {Meta} = Card
const {Paragraph, Title} = Typography;

class Accommodation extends React.Component<any, any> {
    state = {
        collapsed: false,
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    nextPath(path) {
        this.props.history.push(path);
    }

    handleClick(url) {
        window.open(url, '_blank');
    }

    render() {
        return (
            <Layout style={{minHeight: '100vh'}}>
                <Navigation page={'accommodation'} history={this.props.history}/>
                <Layout className="site-layout" style={{backgroundColor: '#fff0f6'}}>
                    <Content style={{margin: '0 16px'}}>
                        <Row>
                            <Title level={2} style={{fontFamily: 'Brush Script MT', fontSize: 40, textAlign: "center"}}>Looking
                                for somewhere to stay?</Title>
                            <Paragraph style={{textAlign: "center"}}>These are a few nearby places to stay, within a 15
                                minute walk from the Taxi
                                pickup/drop off point. Click on the image to take you to each website.</Paragraph>
                        </Row>
                        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                            <Col xs={24} xl={8}>
                                <Card
                                    hoverable
                                    size="default"
                                    onClick={this.handleClick.bind(this, "https://www.premierinn.com/gb/en/hotels/wales/monmouthshire/monmouth/monmouth.html")}
                                    cover={<img alt="premier inn" height={320}
                                                src={premmierInn}/>}
                                >
                                    <Meta title="Premier Inn Monmouth" description="Portal Rd, Monmouth NP25 5FP"/>
                                </Card>,
                            </Col>
                            <Col xs={24} xl={8}>
                                <Card
                                    hoverable
                                    size="default"
                                    onClick={this.handleClick.bind(this, "https://www.tripadvisor.co.uk/Hotel_Review-g552009-d1059418-Reviews-Punch_House-Monmouth_Monmouthshire_South_Wales_Wales.html")}
                                    cover={<img alt="the punch house" height={320}
                                                src={punchHouse}/>}
                                >
                                    <Meta title="The Punch House" description="Agincourt Square, Monmouth NP25 3BT"/>
                                </Card>,
                            </Col>
                            <Col xs={24} xl={8}>
                                <Card
                                    hoverable
                                    onClick={this.handleClick.bind(this, "https://www.createsmonmouth.com/bandb")}
                                    size="default"
                                    cover={<img alt="creates" height={320}
                                                src={creates}/>}
                                >
                                    <Meta title="Creates" description="7 Church St, Monmouth NP25 3BX"/>
                                </Card>,
                            </Col>
                            <Col xs={24} xl={8}>
                                <Card
                                    hoverable
                                    onClick={this.handleClick.bind(this, "https://www.jdwetherspoon.com/hotels/wales/monmouthshire/the-kings-head")}
                                    size="default"
                                    cover={<img alt="kings head" height={300}
                                                src={kingsHead}/>}
                                >
                                    <Meta title="The Kings Head"
                                          description="8 Agincourt Square, Monmouth NP25 3DY Wales"/>
                                </Card>,
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default Accommodation;
