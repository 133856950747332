import React from 'react';
import '../App.css';
import {
    Menu, Layout, Row, Empty, Col, Steps, Table,
} from 'antd';
import 'antd/dist/antd.css';
import {Navigation} from "../components/navigation";
import {Content} from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
const { Step } = Steps;
import type { ColumnsType } from 'antd/es/table';

interface DataType {
    key: string;
    name: string;
    number: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: text => <a>{text}</a>,
    },
    {
        title: 'Contact Number',
        dataIndex: 'number',
        key: 'number',
    },
];

const data: DataType[] = [
    {
        key: '1',
        name: 'ACE Taxis',
        number: "07754 174596",
    },
    {
        key: '2',
        name: 'Alpha Cab',
        number: "07708 207123",
    },
    {
        key: '3',
        name: 'Amber Cabs',
        number: "01600 712200"
    },
    {
        key: '4',
        name: 'Taxi Tacsi',
        number: "01291 691870"
    },
    {
        key: '5',
        name: "Jock's Journeys ",
        number: "07456 808427"
    },
    {
        key: '6',
        name: "Monnow Taxi",
        number: "07968 059409"
    },
    {
        key: '7',
        name: "Prestige Taxis",
        number: "07904 106194"
    },
];

class Travel extends React.Component<any, any> {
    state = {
        collapsed: false,
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    nextPath(path) {
        this.props.history.push(path);
    }

    render() {
        return (
            <Layout style={{minHeight: '100vh', backgroundColor: '#fff0f6'}}>
                <Navigation page={'travel'} history={this.props.history}/>
                <Layout className="site-layout" style={{backgroundColor: '#fff0f6'}}>
                    <Content style={{margin: '0 16px'}}>
                        <Row>
                            <Title level={2} style={{fontFamily: 'Brush Script MT', fontSize: 40, textAlign: "center"}}>Here's some useful information about the timeline of the day</Title>
                        </Row>
                        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                            <Row><br/><br/></Row>
                            <Col xs={24} xl={8}/>
                            <Col xs={24} xl={8}>
                                <Steps direction="vertical" current={6}>
                                    <Step title="Morning Transport Arrives (9:30am)" description="Transportation will arrive at 9:30am on the day of the wedding. A coach will be at Premmier Inn and a mini bus will be at Monmouth town centre to collect everyone" />
                                    <Step title="Morning Transport Leaves (9:45am)" description="Transportation will aim to leave from both pickup points at 9:45am. We will have people with lists so the bus won't leave without you. Please don't leave anything on the bus." />
                                    <Step title="Decant the coach" description="Because our wonderful church is down a country lane sadly the coach can not fit. To solve this everyone will decant onto a minibus for the final leg of the journey" />
                                    <Step title="Enjoy the wedding" description="We know this has been a military procedure but we really appreciate it " />
                                    <Step title="To the reception" description="Everyone will be able to get onto mini busses to the reception. Luckily it's only 9 minutes so won't be long" />
                                    <Step title="Taxis home (10:30pm, 11pm, 12pm)" description="We've provided mini buses back to premier inn and monmouth center at 10:30pm, 11pm and 12pm. If you'd like to go elseware, sadly you'll need to arrange that yourself in advance" />
                                </Steps>
                            </Col>
                        </Row>
                        <Row>
                            <Paragraph style={{textAlign: "center"}}>We appreciate that the evening taxis might not be going in your direction. To get a taxi to your door please contact the following companies in advance.</Paragraph>
                        </Row>
                        <Row>
                            <Table columns={columns} dataSource={data} />
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default Travel;
