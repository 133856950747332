import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './screens/Home'
import Blog from './screens/Blog'
import Accommodation from "./screens/Accommodation";
import Venue from "./screens/Venue"
import Photos from "./screens/Photos";
import GiftList from "./screens/GiftList";
import Food from "./screens/Food"
import Travel from "./screens/Travel"

class App extends Component {
  render() {
    return (
        <Router>
          <div>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/blog' component={Blog} />
              <Route path='/accommodation' component={Accommodation} />
              <Route path='/venue' component={Venue} />
              <Route path='/photos' component={Photos} />
              <Route path='/gift' component={GiftList} />
              <Route path='/food' component={Food} />
              <Route path='/travel' component={Travel} />
            </Switch>
          </div>
        </Router>
    );
  }
}

export default App;
