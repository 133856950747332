import React from 'react';
import '../App.css';
import {
    Menu, Layout, Row, Col
} from 'antd';
import 'antd/dist/antd.css';
import {Navigation} from "../components/navigation";
import {Content} from "antd/es/layout/layout";
import {Images} from "../components/weddingPhotos";


class Photos extends React.Component<any, any> {
    state = {
        collapsed: false,
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    nextPath(path) {
        this.props.history.push(path);
    }

  

    render() {
        return (
            <Layout style={{minHeight: '100vh', backgroundColor: '#fff0f6'}}>
                <Navigation page={'photos'} history={this.props.history}/>
                <Layout className="site-layout" style={{backgroundColor: '#fff0f6'}}>
                            <Images/>
                </Layout>
            </Layout>
        );
    }
}

export default Photos;
