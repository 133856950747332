import React from "react";
import {Steps} from "antd";

const {Step} = Steps;

export class WeddingSteps extends React.Component<any, any>  {
    render() {
        return (
            <Steps current={this.props.step} responsive={true} labelPlacement="vertical">
                <Step
                    title="Alex proposes"
                    description="It was very scary but Alex popped the question"
                />
                <Step
                    title="Explore Venues"
                    description="Lockdown can't stop us. We've started looking at venues"
                />
                <Step
                    title="Let's get booking"
                    description="This is it we've chosen our perfect venue can't wait to show you"
                />
                <Step
                    title="Invitations Sent"
                    description="We've sent you our invitations can't wait to hear from you"
                />
                <Step
                    title="Stag & Hen do!"
                    description="Party!"
                />
                <Step
                    title="Operation get Emily to the church on time"
                    description="Today is the big day. We're both super excited"
                />
            </Steps>
        )
    }
}
