import React from 'react';
import 'antd/dist/antd.css';
import {
    Image, Row, Col
} from 'antd';
import {Content} from "antd/es/layout/layout";

function importAll(r) {
    let images = {};
    r.keys().map((item) => { images[item.replace('./', '')] = r(item); });
    return images;
}
   
const landscapeImages = importAll(require.context('../assets/photosLandscape', false, /\.(png|jpe?g|svg)$/));
const portraitImages = importAll(require.context('../assets/photosPortrait', false, /\.(png|jpe?g|svg)$/));

export class Images extends React.Component<any, any>  {
    nextPath(path) {
        this.props.history.push(path);
    }

    state = {
        collapsed: false,
    };

    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    render() {
        return (
            <div className='kat'>
                <Content style={{margin: '0 16px'}}>
                    <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                        {Object.keys(landscapeImages).map((key) => (
                            <Col xs={24} xl={8}>
                                <Image width={400} src={landscapeImages[key].default} />
                            </Col>
                        ))}
                    </Row>
                    <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                        {Object.keys(portraitImages).map((key) => (
                            <Col xs={24} xl={8}>
                                <Image width={400} src={portraitImages[key].default} />
                            </Col>
                        ))}
                    </Row>
                </Content>
            </div>
        )
    }
}
