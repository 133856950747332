import React from 'react';
import '../App.css';
import {
    Menu, Layout, Card, Typography, List, Avatar
} from 'antd';
import ourEngagement from '../assets/OurEngagement.jpeg';
import emilyBlogPhoto from '../assets/emilyBlogLogo.jpeg'
import alexBlogPhoto from '../assets/alexBlogLogo.png'
import breconBeacons from '../assets/brecon-beacons-national-park.webp'
import bridesmaidsBoxes from '../assets/bridesmaidsBoxes.jpeg'
import signedSealedDelivered from '../assets/signedSealedDelivered.jpeg'
import jessOnGroomsmenGift from '../assets/jessOnGroomsmenGift.jpeg'
import stag from '../assets/stag.jpeg'
import 'antd/dist/antd.css';
import {Navigation} from "../components/navigation";
import {Content} from "antd/es/layout/layout";

const {Title} = Typography;

const listData = [
    {
        title: 'The Stag',
        avatar: alexBlogPhoto,
        content: 'On Saturday the 10th of September I had my Stag Do and what a great Stag it was. I\'m so grateful for everyone who attended what will long be remembered as one of favourite weekends (wedding excluded of course). Harry and Kieran created an incredible itinerary a tour around the tiny rebel brewery, an escape room, and plenty of drinks. Next stop the big day.\n',
        image: stag
    },
    {
        title: 'Groomsmen',
        avatar: alexBlogPhoto,
        content: '\n' +
            'After the great brown paper shortage, we are pleased to announce the groomsmen boxes are on their way. Keep an eye on your postboxes!',
        image: jessOnGroomsmenGift
    },
    {
        title: 'Signed, sealed and (almost) delivered.',
        avatar: emilyBlogPhoto,
        content: 'Keep an eye on your postboxes, we have sent out our save the dates! Thank you everyone for all your addresses and whilst we can\'t promise we won\'t be signing you up for the Mormon Church, it makes sending them out a lot easier! Jesse was very excited to help open the delivery for us. \n' +
            '\n' +
            'We are so excited that lock down restrictions may be easing, which means we can continue booking the venue and making more arrangements for the event of 2022.\n' +
            '\n' +
            'The bridesmaids have been busy getting to know each other and  planning the Hen Do. The groomsmen are still unaware - apparently there is a shortage of brown paper 🙈',
        image: signedSealedDelivered
    },
    {
        title: 'Bridal Party',
        avatar: emilyBlogPhoto,
        content: 'On Tuesday we sent off our bridesmaid proposal boxes to ask the Bridal Party - lockdown style! \n' +
            ' We cannot wait to have you all involved with our special day. Unfortunately no-one told Jesse that he was not asked. \n' +
            'Next stop the groomsmen',
        image: bridesmaidsBoxes
    },
    {
        title: `Hunting for that perfect venue`,
        avatar: alexBlogPhoto,
        content: 'That perfect venue does exist and lockdown can\'t stop our excitement. We\'ve started\n' +
            '                                attending\n' +
            '                                some virtual viewings for some incredible venues in Wales. Can\'t wait to share more\n' +
            '                                with\n' +
            '                                you soon!',
        image: breconBeacons
    },
    {
        title: `The Engagement`,
        avatar: emilyBlogPhoto,
        content: 'For my birthday in April, we had booked a glamping experience just outside of\n' +
            '                                Trellech,\n' +
            '                                Monmouthshire.\n' +
            '                                After re-scheduling 3 times, we found our way down the country lanes, through thick\n' +
            '                                fog\n' +
            '                                on a Friday in November\n' +
            '                                to our wooden pod. Alex had arrived earlier to set up, but i was happy to admire the\n' +
            '                                views -\n' +
            '                                unaware of what was planned. I was guided towards our gorgeous pod and saw rose\n' +
            '                                petals\n' +
            '                                on the floor in the shape of a heart.\n' +
            '                                As I turned to ask what was the occasion, I saw Alex holding a ring box - looking\n' +
            '                                very\n' +
            '                                nervous!\n' +
            '                                He managed to say the words "How would you like to spell Le Peltier for the rest of\n' +
            '                                your\n' +
            '                                life?" It took me a moment\n' +
            '                                to process, whilst Alex insisted that I spelt the name - space included. Before it\n' +
            '                                was a\n' +
            '                                definite - "Yes!"',
        image: ourEngagement
    }
]

class Blog extends React.Component<any, any> {
    state = {
        collapsed: false,
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    nextPath(path) {
        this.props.history.push(path);
    }

    render() {
        return (
            <Layout style={{minHeight: '100vh', backgroundColor: '#fff0f6'}}>
                <Navigation page={'blog'} history={this.props.history}/>
                <Layout className="site-layout" style={{backgroundColor: '#fff0f6'}}>
                    <Content style={{margin: '0 16px'}}>
                        <Card title={<Title level={1}>Our Wedding Blog</Title>} bordered={false}
                              style={{backgroundColor: '#fff0f6'}} headStyle={{
                            backgroundColor: '#fff0f6',
                            fontFamily: 'Brush Script MT',
                            textAlign: "center"
                        }}>
                            <List
                                itemLayout="vertical"
                                size="large"
                                pagination={{
                                    onChange: page => {
                                        console.log(page);
                                    },
                                    pageSize: 3,
                                }}
                                dataSource={listData}
                                renderItem={item => (
                                    <List.Item
                                        key={item.title}
                                        extra={
                                            <img
                                                width={272}
                                                alt="logo"
                                                src={item.image}
                                            />
                                        }
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar src={item.avatar}/>}
                                            title={item.title}
                                        />
                                        {item.content}
                                    </List.Item>
                                )}
                            />,
                        </Card>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default Blog;
