import React from 'react';
import 'antd/dist/antd.css';
import {
    Menu,
    Layout
} from 'antd';
import {
    BankOutlined,
    CarOutlined,
    FileOutlined,
    HeartOutlined,
    HomeOutlined,
    WifiOutlined,
    PictureOutlined,
    TagsOutlined
} from '@ant-design/icons';

const {Sider} = Layout;
let width = window.innerWidth;

export class Navigation extends React.Component<any, any>  {
    nextPath(path) {
        this.props.history.push(path);
    }

    state = {
        collapsed: false,
    };

    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    render() {
        let {collapsed} = this.state;

        if (width <= 768) {
           collapsed = true
        }

        return (
            <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
                <div className="logo" />
                <Menu theme="dark" defaultSelectedKeys={[this.props.page]} mode="inline">
                    <Menu.Item key="home" icon={<HomeOutlined /> } onClick={() => this.nextPath('/')}>
                        Home
                    </Menu.Item>
                    <Menu.Item key="accommodation" icon={<WifiOutlined />} onClick={() => this.nextPath('/accommodation')}>
                        Accommodation
                    </Menu.Item>
                    <Menu.Item key="blog" icon={<FileOutlined />} onClick={() => this.nextPath('/blog')}>
                        Blog
                    </Menu.Item>
                    <Menu.Item key="gift" icon={<TagsOutlined />} onClick={() => this.nextPath('/gift')}>
                        Gift List
                    </Menu.Item>
                    <Menu.Item key="photos" icon={<PictureOutlined />} onClick={() => this.nextPath('/photos')}>
                        Photos
                    </Menu.Item>
                    <Menu.Item key="venue" icon={<BankOutlined />} onClick={() => this.nextPath('/venue')}>
                        Venue
                    </Menu.Item>
                    <Menu.Item key="travel" icon={<CarOutlined />} onClick={() => this.nextPath('/travel')}>
                        Travel
                    </Menu.Item>
                </Menu>
            </Sider>
        )
    }
}
